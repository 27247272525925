import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionWhatSellingListings.module.css';
import { useIntl } from '../../../../util/reactIntl';
import { ListingCard, NamedLink } from '../../../../components';

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 265px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionWhatSellingListings = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    isInsideContainer,
    options,
    listings,
    isLoading,
  } = props;

  const intl = useIntl();

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasListings = listings.length > 0;

  const classes = classNames(
    rootClassName || className,
    css.wrapper,
    defaultClasses.sectionDetails
  );

  const ctaButtonClasses = classNames(defaultClasses.ctaButton, css.ctaButton);

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields || hasListings ? (
        <header className={classes}>
          <Field data={title} options={fieldOptions} className={css.header} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={ctaButtonClasses} options={fieldOptions} />
        </header>
      ) : null}
      {isLoading ? (
        <div className={css.loaderWrapper}>
          <div className={css.loader}>
            <div></div>
          </div>
        </div>
      ) : hasListings ? (
        <div
          className={classNames(
            defaultClasses.blockContainer,
            css.container,
            getColumnCSS(numColumns),
            {
              [css.noSidePaddings]: isInsideContainer,
            }
          )}
        >
          {listings.map(l => (
            <ListingCard key={l.id.uuid} listing={l} intl={intl} />
          ))}
        </div>
      ) : (
        <div className={css.noListingWrapper}>
          <div className={css.containerNoListing}>
            <div className={css.card}>
              <div className={classNames(css.image, css.skeleton)}></div>
              <div className={css.content}>
                <div className={classNames(css.title, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
              </div>
            </div>
            <div className={css.card}>
              <div className={classNames(css.image, css.skeleton)}></div>
              <div className={css.content}>
                <div className={classNames(css.title, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
              </div>
            </div>
            <div className={css.card}>
              <div className={classNames(css.image, css.skeleton)}></div>
              <div className={css.content}>
                <div className={classNames(css.title, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
              </div>
            </div>
            <div className={css.card}>
              <div className={classNames(css.image, css.skeleton)}></div>
              <div className={css.content}>
                <div className={classNames(css.title, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
                <div className={classNames(css.description, css.skeleton)}></div>
              </div>
            </div>
          </div>
          <div className={css.NewListingButton}>
            <h3>Be the first to list on PCIFIC</h3>
            <NamedLink name="NewListingPage" className={css.newListingAction}>
              Post a New Listing
            </NamedLink>
          </div>
        </div>
      )}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionWhatSellingListings.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
  isLoading: false,
};

SectionWhatSellingListings.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
  isLoading: bool,
};

export default SectionWhatSellingListings;
